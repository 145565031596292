export const mibPrefix = 'mib';
export const clientId = 'System-Service-Dias';
export const clientSecret = '24U7tcNLHRSvvjrr9sFEXGyMTpzk59mG';
// export const authorizeUrl = 'http://localhost:8809/oauth/authorize';
export const authorizeUrl = `${process && process.env && process.env.REACT_APP_HOST_OAUTH}/oauth/authorize`;

// export const redirectUri = 'http://localhost:3002/advanced-management/authorization_code';
// export const redirectUri = 'https://mobinspect.rasse.local/advanced-management/authorization_code';

export const redirectUri =
    process && process.env
    && (process.env.NODE_ENV === 'development'
        ? `${process && process.env && process.env.REACT_APP_HOST_MAIN}${process.env.REACT_APP_PORT}${process.env.PUBLIC_URL}/authorization_code`
        : `${process && process.env && process.env.REACT_APP_HOST_MAIN}${process.env.PUBLIC_URL}/authorization_code`);

// export const logoutUrl = 'http://localhost:8809/oauth/logout';
export const logoutUrl = `${process && process.env && process.env.REACT_APP_HOST_OAUTH}/oauth/logout`;
