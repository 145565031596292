import React from 'react';
import PropTypes from 'prop-types';
import {Route, Switch, Redirect} from 'react-router';
import NotFound from '../Errors/NotFound';

const Routes = props => {

    const {paths} = props;

    const pathPrefix = process && process.env && process.env.PUBLIC_URL;

    const getRoutes = () => {
        let routes = [];
        for (let path in paths) {
            if (!paths[path].isGroup) {
                // console.log("paths[path].exact => ", paths[path].path, paths[path].exact);
                const Component = paths[path].component;
                routes.push(
                    <Route
                        exact={paths[path].exact !== undefined ? paths[path].exact : true}
                        key={path}
                        path={`${pathPrefix}${paths[path].path}`}
                        render={props => (<Component {...props} match={{...props.match, title: paths[path].title}}/>)}
                    />
                );
            }
        }
        for (let path in paths) {
            if (paths[path].redirect) {
                routes.push(
                    <Redirect
                        exact
                        key={path}
                        from={`${pathPrefix}${paths[path].path}`}
                        to={`${pathPrefix}${paths[path].redirect}`}
                    />
                );
            }
        }
        // console.log("routes => ", routes);
        return routes;
    };

    return (
        <Switch>
            {/*<Route*/}
            {/*	path={paths.MENU_EDITOR.path}*/}
            {/*	component={paths.MENU_EDITOR.component}*/}
            {/*/>*/}
            {getRoutes()}
            <Route path={`${pathPrefix}/404`} component={NotFound}/>
            <Redirect
                from={'*'}
                to={`${pathPrefix}/404`}
            />
        </Switch>
    );
};

Routes.propTypes = {
    paths: PropTypes.object,
};

export default Routes;
