import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import Menu from '../Menu/Menu';
import withAuth from "./Auth/withAuth";
import {AppContext} from '../../context';
import {mibPrefix} from '../../constants/auth.constants';
import DynamicMenu from '../DynamicMenu/DynamicMenu';
import Routes from '../Routes/Routes';

const {Sider} = Layout;

const App = (props) => {
	const [toggleCollapsed, setToggleCollapsed] = useState(
		localStorage.getItem('menuCollapsed') === 'true'
	);
	const [checkingToken, setCheckingToken] = useState(false);

	const {
	    location,
        history,
        auth,

        // New Lib Props
        // Routes, // Компонент работы с роутами
        paths,
        menuProps,

        titlePrefix,
        titleSuffix,
        userProfile
	} = props;
    // console.log({auth}, {location});
	if(auth && !auth.access_token){
		history.push(paths.LOGIN.path);
		return null;
	}
	else {
		if(!checkingToken){
			setCheckingToken(true);
			// checkTokenRequest().then().catch();
			// setInterval(function() {
			// 	checkTokenRequest().then().catch();
			// }, 5000);
		}
		return (
            <AppContext.Provider value={{paths: paths, titlePrefix: titlePrefix, titleSuffix: titleSuffix, userProfile}}>
                <Layout className={`${mibPrefix}-root-layout`}>
                    <Sider collapsed={toggleCollapsed} collapsedWidth={60} width={320}>
                        {menuProps.type === 'recursive'
                            ? <DynamicMenu
                                onToggleCollapsed={() => {
                                    setToggleCollapsed(!toggleCollapsed);
                                    localStorage.setItem(
                                        'menuCollapsed',
                                        (!toggleCollapsed).toString()
                                    );
                                }}
                                toggleCollapsed={toggleCollapsed}
                                path={location.pathname}
                                {...menuProps}
                            />
                            : <Menu
                                onToggleCollapsed={() => {
                                    setToggleCollapsed(!toggleCollapsed);
                                    localStorage.setItem(
                                        'menuCollapsed',
                                        (!toggleCollapsed).toString()
                                    );
                                }}
                                toggleCollapsed={toggleCollapsed}
                                path={location.pathname}
                                menu={menuProps.menu}
                                ToggleBtnRight={menuProps.ToggleBtnRight}
                                LogoSmall={menuProps.LogoSmall}
                                LogoBig={menuProps.LogoBig}
                                ToggleBtnLeft={menuProps.ToggleBtnLeft}
                            />}
                    </Sider>
                    <Layout>
                        <Routes paths={paths}/>
                    </Layout>
                </Layout>
            </AppContext.Provider>
		);
	}
};

App.propTypes = {
    // auth: PropTypes.object,
    // Routes: PropTypes.func,
    paths: PropTypes.object,
    menuProps: PropTypes.shape({
        type: PropTypes.string,
        menu: PropTypes.arrayOf(PropTypes.object),
        requestLoadMenu: PropTypes.func,
        ToggleBtnRight: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        LogoSmall: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        LogoBig: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        ToggleBtnLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    }),

    /**
     * Prefix in title page default ''
     */
    titlePrefix: PropTypes.string,

    /**
     * Suffix in title page default ''
     */
    titleSuffix: PropTypes.string,
};

App.defaultProps = {
    titlePrefix: '',
    titleSuffix: '',
};

export default withAuth(App);
