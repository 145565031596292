import React from 'react';
import {Result} from 'antd';
import BasePage from '../App/BasePage';

const NotFound = () => {
    return (
        <BasePage style={{justifyContent: 'center'}}>
            <Result
                status='404'
                title='404'
                subTitle='Страница не найдена, передите в другой пункт меню'
            />
        </BasePage>
    );
};

export default NotFound;
