import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import withAuth from "./withAuth";
import {parseQueryParams} from "../../../utils/baseUtils";
import {accessTokenRequest} from "../../../apis/auth.api";
import {setCookie} from "../Cookie/cookie";

const AuthorizationCode = props => {
    const [loading, setLoading] = useState(false);

    const { location, history, redirectUrl } = props;

    let queryParams = parseQueryParams(location.search);
    // console.log("location search", location, queryParams);
    // console.log("AuthorizationCode props", props);

    useEffect(() => {
        if(queryParams && queryParams.code && !loading) {
            setLoading(true);
            accessTokenRequest(queryParams.code)
                .then((response) => {
                    if(response.status === 200) {
                        console.log("Token response then", response);

                        // Save to redux-store and localStorage
                        props.setUser(response.data);

                        // Save to cookies
                        setCookie('code_challenge', response.data.code_challenge, { path: '/' });

                        // Finish loading token
                        setLoading(false);

                        // Redirect to main page
                        history.push(redirectUrl);
                    } else {
                        console.log("Token response error", response.status);
                    }
                })
                .catch((error) => {
                    console.log("Token error", error);
                })
        }
    }, []);
    return null;
};

AuthorizationCode.propTypes = {
    redirectUrl: PropTypes.string,
};

export default withAuth(AuthorizationCode);
