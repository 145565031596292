import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Layout, Popover, Space} from 'antd';
import {LeftOutlined} from '@ant-design/icons';
import withAuth from "./Auth/withAuth";

import {UserIcon} from '../../imgs/header/user';
import UserProfile from "./Header/UserProfile";

import {AppContext} from '../../context';
import {mibPrefix} from '../../constants/auth.constants';

const {Content} = Layout;

const version = process && process.env && process.env.REACT_APP_VERSION;

const BasePage = props => {
    const {goBack, title, extra, history, auth, className, style, children} = props;
    const {titlePrefix, titleSuffix, userProfile} = useContext(AppContext);
    // console.log("BasePage appContext => ", appContext);

    const getContentCls = () => {
        let cls = [`${mibPrefix}-root-layout-content`];
        if (className)
            cls.push(className);
        return cls.join(' ')
    };

    const getPopoverTitle = (
        <div className={`${mibPrefix}-header-popover-title`}>
            <span><UserIcon width="32" height="42"/></span>
            <span>{auth.username} [{auth.userId}]</span>
        </div>
    );

    const onClickGoBack = () => {
        history.goBack();
    }

    const getGoBackTitle = () => {
        if (goBack !== undefined) {
            if (goBack === true)
                return "Назад";
            else
                return goBack;
        } else return ''
    }

    const goBackTitle = getGoBackTitle()

    const goBackStyle = {
        padding: goBackTitle.trim() === '' ? '4px 8px' : '4px 12px 4px 8px',
        backgroundColor: '#f2f2f2',
        borderRadius: '8px',
        cursor: 'pointer'
    }

    document.title = `${titlePrefix}${title}${titleSuffix}`;

    return (
        <React.Fragment>
            <div className={`${mibPrefix}-header`}>
                <Space size={16}>
                    {goBack !== undefined
                        ? <Space size={0} onClick={onClickGoBack} style={goBackStyle}>
                            <LeftOutlined style={{fontSize: '16px', marginTop: '4px', color: '#3478f6'}}/>
                            <div style={{fontSize: '14px', color: '#3478f6'}}>{goBackTitle}</div>
                        </Space>
                        : null}
                    <div style={{fontSize: '16px', fontWeight: 600}}>
                        {title}
                    </div>
                    <div>
                        {extra}
                    </div>
                </Space>

                <div className={`${mibPrefix}-profile-control`}>
                    <div className={`${mibPrefix}-header-version`}>
                        {`${version}`}
                    </div>
                    <Popover
                        overlayClassName={`${mibPrefix}-header-popover`}
                        arrowPointAtCenter
                        placement='bottomRight'
                        title={getPopoverTitle}
                        content={<UserProfile data={userProfile}/>}
                        trigger='click'
                    >
                        <div className={`${mibPrefix}-header-user-profile`}>
                                        <span className={`${mibPrefix}-header-title`}>
                                            <div>{auth.username}</div>
                                        </span>
                            <span className={`${mibPrefix}-header-icon`}>
                                            <UserIcon/>
                                        </span>
                        </div>
                    </Popover>
                </div>
            </div>
            <Content
                className={getContentCls()}
                style={{...style, background: '#fff'}}
            >
                {children}
            </Content>
        </React.Fragment>
    );
};

BasePage.propTypes = {
    /**
     * String CSS classes
     */
	className: PropTypes.string,

    /**
     * Style object
     */
    style: PropTypes.object,

    /**
     * Путь обработки default match.path
     */
    path: PropTypes.string,
};

BasePage.defaultProps = {
};

export default withAuth(BasePage);
