import React from 'react';
import PropTypes from 'prop-types';
import withAuth from './withAuth';

const Access = props => {
    const {auth, roles, children} = props;

    // const authRoles = auth.roles
    //     .replace('[', '')
    //     .replace(']', '')
    //     .split(', ');
    //
    // // console.log(props);
    // for (let index in roles) {
    //     if (authRoles.includes(roles[index])) {
    //         console.log("HAS Access");
    //         return <React.Fragment>{children}</React.Fragment>;
    //     }
    // }
    // console.log("HAS NOT Access");
    // return null;

    if(hasAccess(auth, roles))
        return <React.Fragment>{children}</React.Fragment>;
    else
        return null;
};

Access.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired
};

Access.defaultProps = {
    roles: []
};

export default withAuth(Access);

export const checkAccess = (auth, roles) => {
    return hasAccess(auth, roles)
};


const hasAccess = (auth, roles) => {

    const authRoles = auth.roles
        .replace('[', '')
        .replace(']', '')
        .split(', ');

    // console.log(props);
    for (let index in roles) {
        if (authRoles.includes(roles[index])) {
            return true;
        }
    }
    return false;
};
