import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import {Popover, Tooltip} from 'antd';
import {mibPrefix} from '../../constants/auth.constants';

const Div = props => {
	return <div>{props.children}</div>;
};

const MenuItems = (menu, currentPath, toggleCollapsed) =>
	menu.map(item => {
		if (toggleCollapsed) {
			if (item.children && item.children.length > 0) {
				const PopoverContent = item.children.map(childItem => {
					return MenuItem(
						childItem,
						NavLink,
						currentPath,
						toggleCollapsed,
                        `${mibPrefix}-popover-menu-item`,
						true
					);
				});
				return (
					<Popover
						key={item.path}
						overlayClassName={`${mibPrefix}-menu-popover`}
						placement='rightTop'
						arrowPointAtCenter
						content={PopoverContent}
						trigger='click'
					>
						{MenuItem(
							item,
							Div,
							currentPath,
							toggleCollapsed,
                            `${mibPrefix}-menu-item`,
							false
						)}
					</Popover>
				);
			} else {
				return (
					<Tooltip
						key={item.path}
						placement='rightTop'
						title={item.title}
					>
						{MenuItem(
							item,
							NavLink,
							currentPath,
							toggleCollapsed,
                            `${mibPrefix}-menu-item`,
							false
						)}
					</Tooltip>
				);
			}
		} else {
			if (item.children && item.children.length > 0) {
				return (
					<div key={item.path} className={`${mibPrefix}-sub-menu`}>
						<div className={`${mibPrefix}-sub-menu-item`}>
							<span
								role='img'
								aria-label='home'
								className='anticon'
							>
								{' '}
								{item.icon ? <item.icon /> : null}{' '}
							</span>
							<span>{item.title}</span>
						</div>
						{item.children.map(childItem => {
							return MenuItem(
								childItem,
								NavLink,
								currentPath,
								toggleCollapsed,
                                `${mibPrefix}-menu-item`,
								true
							);
						})}
					</div>
				);
			} else {
				return MenuItem(
					item,
					NavLink,
					currentPath,
					toggleCollapsed,
                    `${mibPrefix}-menu-item`,
					true
				);
			}
		}
	});

const MenuItem = (
	item,
	ItemComponent,
	currentPath,
	toggleCollapsed,
	className,
	withTitle
) => {
    const getItemCls = () => {
        let cls = [className];
        if (currentPath.startsWith(item.path))
            cls.push('active');
        if (toggleCollapsed)
            cls.push('collapsed');
        return cls.join(' ')
    };
    return (
        <div
            key={item.path}
            className={getItemCls()}
        >
            <ItemComponent to={item.path}>
                <span role='img' aria-label='home' className='anticon'>
                    {' '}
                    {item.icon ? <item.icon/> : null}{' '}
                </span>

                {withTitle ? <span>{item.title}</span> : null}
            </ItemComponent>
        </div>
    )
};

const Menu = props => {
	const {
	    toggleCollapsed,
        onToggleCollapsed,
        path,
        menu,
        ToggleBtnRight,
        LogoSmall,
        LogoBig,
        ToggleBtnLeft
    } = props;

	// console.log('classes', classes);

    const menuCls = `${mibPrefix}-menu ${toggleCollapsed ? 'collapsed' : ''}`;
    const toggleBtnCls = `${mibPrefix}-toggle-btn ${toggleCollapsed ? 'collapsed' : ''}`;

	return (
		<div className={menuCls}>
			<div
				className={toggleBtnCls}
				onClick={onToggleCollapsed}
			>
                { toggleCollapsed && <div className={`${mibPrefix}-toggle-btn-caret-right`}><ToggleBtnRight/></div> }
                {
                    toggleCollapsed
                        ? <div><LogoSmall className={`${mibPrefix}-toggle-btn-logo-img`}/></div>
                        : <div><LogoBig className={`${mibPrefix}-toggle-btn-logo-img`}/></div>
                }
                { !toggleCollapsed && <div><ToggleBtnLeft className={`${mibPrefix}-toggle-btn-caret-left`}/></div> }
			</div>
			{MenuItems(menu, path, toggleCollapsed)}
		</div>
	);
};

Menu.propTypes = {
	onToggleCollapsed: PropTypes.func.isRequired,
	toggleCollapsed: PropTypes.bool.isRequired,
    path: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.object),
    ToggleBtnRight: PropTypes.object,
    LogoSmall: PropTypes.object,
    LogoBig: PropTypes.object,
    ToggleBtnLeft: PropTypes.object,
};

Menu.defaultProps = {};

export default Menu;
