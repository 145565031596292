import React, {useState} from 'react';
import withAuth from "../Auth/withAuth";
import {notification, Modal, Form, Input} from 'antd';
import {apiChangePassword} from '../../../apis/data.api';

const ChangePasswordModal = props => {

    const [visible, setVisible] = useState(false);
    const [form] = Form.useForm();

    const onCancel = () => setVisible(false);
    const onOk = () => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                _onSaveRow(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    };

    const _onSaveRow = (row) => {
        const saveRow = {...row, id: props.auth.userId};
        const method = 'POST';
        console.log("Modal Events => type: ", method, saveRow);
        apiChangePassword({
            method,
            data: saveRow,
        })
            .then(response => {
                notification.success({
                    message: 'Сохранение прошло успешно'
                });
                setVisible(false );
            })
            .catch(error => {
                console.error('error -> ', error);
                notification.error({
                    message: 'Ошибка при сохранении',
                    description: error.error
                });
            });
    };

    return (
        <React.Fragment>
            <div onClick={() => setVisible(true)}>
                Сменить пароль
            </div>
            <Modal
                title="Смена пароля"
                visible={visible}
                centered={true}
                width={550}
                okText={'Сохранить'}
                onOk={onOk}
                cancelText={'Отмена'}
                onCancel={onCancel}
                zIndex={2000}
            >
                <Form
                    form={form}
                    layout="horizontal"
                    name="form_in_modal"
                    initialValues={{ modifier: 'public' }}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 16}}
                >
                    <Form.Item
                        name="password"
                        label="Пароль"
                        rules={[
                            {
                                required: true,
                                message: 'Заполните пароль',
                            },
                        ]}
                    >
                        <Input.Password placeholder={'Введите пароль'}/>
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        label="Подтверждение пароля"
                        dependencies={['password']}
                        placeholder={'Введите пароль'}
                        rules={[
                            {
                                required: true,
                                message: 'Заполните пароль',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('Пароли должны совпадать');
                                },
                            }),
                        ]}
                    >
                        <Input.Password placeholder={'Введите пароль'}/>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    );
};

ChangePasswordModal.propTypes = {

};

export default withAuth(ChangePasswordModal);
