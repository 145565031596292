import React, {useState, useEffect} from 'react';
import {mibPrefix} from '../../constants/auth.constants';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import { Menu, notification } from 'antd';
import {flatten, getDataKeys, getDataKeysWithOutParent} from '../../utils/baseUtils';
import {checkAccess} from '../App/Auth/Access';
import withAuth from '../App/Auth/withAuth';

const antIcons = require('@ant-design/icons');

const { SubMenu } = Menu;

const DynamicMenu = props => {
    const {
        toggleCollapsed,
        onToggleCollapsed,
        path,
        menu,
        ToggleBtnRight,
        LogoSmall,
        LogoBig,
        ToggleBtnLeft,
        requestLoadMenu,
    } = props;

    const [_menu, _setMenu] = useState([]);
    const [menuKeys, setMenuKeys] = useState({});

    const menuCls = `${mibPrefix}-menu ${mibPrefix}-menu-recursive ${toggleCollapsed ? 'collapsed' : ''}`;
    const toggleBtnCls = `${mibPrefix}-toggle-btn ${toggleCollapsed ? 'collapsed' : ''}`;

    const pathPrefix = process && process.env && process.env.PUBLIC_URL;

    useEffect(() => {
        // console.log('menu => ', menu);
        if (requestLoadMenu) {
            requestLoadMenu({data: {}, params: {page: 0, size: 1}})
                .then(response => {
                    const data = response.data;
                    _setMenu(data);
                    getMenuKeys(data);
                })
                .catch(error => {
                    notification.error({
                        message: 'Загрузка конфигов не удалась',
                        description: error.message
                    });
                })
        } else if(menu) {
            _setMenu(menu);
            getMenuKeys(menu);
        }
    }, []);

    useEffect(() => {
        const m = _menu.length !== 0 ? _menu : menu;
        const openKeys = !toggleCollapsed ? flatten(getDataKeys(m, 'path')) : [];
        const flatMenu = flatten(getDataKeysWithOutParent(m, 'path'));
        const selectedKey = flatMenu.find(item => path.startsWith(`${pathPrefix}${item}`));
        setMenuKeys({openKeys: openKeys, selectedKeys: [selectedKey]});
        // console.log("path", {path, openKeys: openKeys, selectedKeys: [selectedKey]});
    }, [path]);

    const menuItemsRender = (menu) => {
        return menu.map((item, index) => {
            // let cls = [];
            // console.log("menuItemsRender -> typeof item.icon", typeof item.icon);
            // console.log("item.title =>",item.title, checkAccess(props.auth, item.roles))
            if(item.sharedForRoles)
                item.roles = JSON.parse(item.sharedForRoles);

            if(!checkAccess(props.auth, item.roles)) return null;
            //Возможно если прировнять к item.children.length >=0 , перестанет скакать кнопки
            if (item.children && item.children.length >0) {
                // return null;
                return (
                    <SubMenu
                        key={item.path}
                        title={item.name ? item.name : item.title}
                        icon={<IconRender icon={item.icon}/>}
                        >
                        {menuItemsRender(item.children)}
                    </SubMenu>
                )
            } else {
                return (
                    <Menu.Item
                        key={item.path}
                        icon={<IconRender icon={item.icon}/>}
                        className={'no-margin'}
                        style={{margin:'0'}}
                    >
                        <NavLink to={`${pathPrefix}${item.path}`}>
                        {/*<NavLink to={item.path}>*/}
                            {item.name ? item.name : item.title}
                        </NavLink>
                    </Menu.Item>
                )
            }
        });
    };

    const IconRender = ({icon}) => {
        if(icon) {
            if (typeof icon === 'object') {
                const Icon = icon;
                return <Icon/>;
            } else {
                const Icon = antIcons[icon];
                return <Icon/>;
            }
        } else return null;
    };

    const getMenuKeys = (menu) => {
        // console.log("getMenuKeys", menu);
        const openKeys = !toggleCollapsed ? flatten(getDataKeys(menu, 'path')) : [];
        const flatMenu = flatten(getDataKeysWithOutParent(menu, 'path'));
        const selectedKey = flatMenu.find(item => path.startsWith(item));
        setMenuKeys({openKeys: openKeys, selectedKeys: [selectedKey]});
        // console.log("getMenuKeys", openKeys, selectedKey);
        // return [openKeys, [selectedKey]];
    };

    const onOpenChange = openKeys => {
        setMenuKeys({...menuKeys, openKeys: openKeys});
    };

    const onSelect = ({ key }) => {
        // console.log("onSelect key => ", key);
        setMenuKeys({...menuKeys, selectedKeys: [key]});
    };

    return (
        <div className={menuCls}>
            <div
                className={toggleBtnCls}
                onClick={onToggleCollapsed}
            >
                { toggleCollapsed && <div className={`${mibPrefix}-toggle-btn-caret-right`}><ToggleBtnRight/></div> }
                {
                    toggleCollapsed
                        ? <div><LogoSmall className={`${mibPrefix}-toggle-btn-logo-img`}/></div>
                        : <div><LogoBig className={`${mibPrefix}-toggle-btn-logo-img`}/></div>
                }
                { !toggleCollapsed && <div><ToggleBtnLeft className={`${mibPrefix}-toggle-btn-caret-left`}/></div> }
            </div>
            <div className={`${mibPrefix}-menu-content`}>
                <Menu
                    mode="inline"
                    openKeys={menuKeys.openKeys}
                    onOpenChange={onOpenChange}
                    selectedKeys={menuKeys.selectedKeys}
                    onSelect={onSelect}
                >
                    {menuItemsRender(_menu)}
                </Menu>
            </div>
        </div>
    );
};

DynamicMenu.propTypes = {
    onToggleCollapsed: PropTypes.func.isRequired,
    toggleCollapsed: PropTypes.bool.isRequired,
    path: PropTypes.string,
    menu: PropTypes.arrayOf(PropTypes.object),
    requestLoadMenu: PropTypes.func,
    ToggleBtnRight: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    LogoSmall: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    LogoBig: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    ToggleBtnLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
};

DynamicMenu.defaultProps = {};

export default withAuth(DynamicMenu);
