import React from 'react';
import withAuth from "../Auth/withAuth";
import {mibPrefix} from '../../../constants/auth.constants';
import {message} from 'antd';

const version = process && process.env && process.env.REACT_APP_VERSION;
const build_date = process && process.env && process.env.REACT_APP_BUILD_DATE;

const defaultItem = {
    title: 'Item',
    onClick: () => message.info('Раздел находится в материализации')
}

const UserProfile = props => {

    const {auth, setUser, data} = props;

    const onClickItem = (onClick) => (e) => {
        onClick(e, auth, setUser);
    }

    const renderData = () => {
        if (data && Array.isArray(data)) {
            return data.map((item, index) => {
                if (item.title === 'separator')
                    return (<div key={index} className={`${mibPrefix}-header-popover-content-separator`}/>)
                else if(item.title === 'version')
                    return (<div key={index} className={`${mibPrefix}-header-popover-content-version`}>
                        <div>{`Версия: ${version} от ${build_date}`}</div>
                    </div>)
                else
                    return (
                    <div key={index} className={`${mibPrefix}-header-popover-content-item`}
                         onClick={onClickItem(item.onClick ? item.onClick : defaultItem.onClick)}
                    >
                        {item.title ? item.title : defaultItem.title}
                    </div>
                )
            })
        }
    }

    return (
        <div className={`${mibPrefix}-header-popover-content`}>
            {renderData()}
        </div>
    );
};

UserProfile.propTypes = {

};

export default withAuth(UserProfile);
