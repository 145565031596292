import axios from 'axios';

/** SAVE DATA API */
export const apiChangePassword = ({data}) => {

    const auth = JSON.parse(localStorage.getItem("auth"));

    if(auth.access_token && auth.userId) {
        return axios({
            url: '/api/management/data/save/setPassword',
            method: 'POST',
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
                userId: auth.userId
            },
            data: data,
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error;
            });
    }
};
